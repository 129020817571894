<template>
  <div class="main-body">
    <div class="card-item">
      <a-form ref="formRef" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit" @finishFailed="onFinishFailed">
        <div class="order-attention">
          <p>网站基础设置</p>
        </div>
        <a-form-item label="站点名称" name="site_name" :rules="[{ required: true, message: '请输入站点名称!' }]">
          <a-input v-model:value="formState.site_name" placeholder="请输入站点名称" />
        </a-form-item>

        <a-form-item label="LOGO" name="site_logo" extra="建议尺寸：128px*64px" :rules="[{ required: true, message: '请上传站点LOGO!' }]">
          <CsImage v-model:imgOne="formState.site_logo" />
        </a-form-item>
        <a-form-item label="关键词" name="keywords" extra="多个使用英文','逗号隔开" :rules="[{ required: true, message: '请输入关键词!' }]">
          <a-input v-model:value="formState.keywords" placeholder="请输入关键词" />
        </a-form-item>
        <a-form-item label="站点描述" name="description">
          <a-textarea v-model:value="formState.description" placeholder="请输入网站描述" />
        </a-form-item>
        <div class="order-attention">
          <p>开发者设置</p>
        </div>
        <a-form-item label="域名" name="domain" extra="不需要带http://或者https://;例如:www.baidu.com" :rules="[{ required: true, message: '请输入域名!' }]">
          <a-input v-model:value="formState.domain" placeholder="请输入域名" />
        </a-form-item>
        <a-form-item label="站点状态" extra="默认正常，可临时关闭网站">
          <a-radio-group v-model:value="formState.site_status">
            <a-radio value="1">正常</a-radio>
            <a-radio value="0">维护中，禁止访问</a-radio>
          </a-radio-group>
        </a-form-item>
		<a-form-item label="关闭原因" v-if="formState.site_status==0">
			<a-textarea v-model:value="formState.site_close" placeholder="请输入关闭原因" />
		</a-form-item>
        <a-form-item label="全站灰度" extra="默认关闭，开启后网站全站显示灰色色调、一般国家哀悼日启用">
          <a-radio-group v-model:value="formState.site_gray">
            <a-radio value="0">禁用</a-radio>
            <a-radio value="1">使用</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
          <a-button type="primary" html-type="submit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import CsImage from "@/components/UpImage";
import { client_save_config, client_get_config } from "@/views/client/api";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CsImage,
  },
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const formRef = ref();
    const formState = reactive({
      appid: appid,
      site_name: "",
      site_logo: "",
      keywords: "",
      description: "",
      domain: "",
      site_gray: "0",
      site_status: "1",
		site_close:'',
    });

    // console.log(appid);
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          client_save_config(formState).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              getInfo();
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const getInfo = () => {
      client_get_config({ appid: appid }).then((res) => {
        if (res.status == 200) {
          formState.site_name = res.data.setting.site_name
            ? res.data.setting.site_name
            : "";
          formState.site_logo = res.data.setting.site_logo
            ? res.data.setting.site_logo
            : "";
          formState.keywords = res.data.setting.keywords
            ? res.data.setting.keywords
            : "";
          formState.description = res.data.setting.description
            ? res.data.setting.description
            : "";
          formState.domain = res.data.setting.domain
            ? res.data.setting.domain
            : "";
          formState.site_gray = res.data.setting.site_gray
            ? res.data.setting.site_gray
            : "0";
          formState.site_status = res.data.setting.site_status
            ? res.data.setting.site_status
            : "1";
formState.site_close = res.data.setting.site_close ? res.data.setting.site_close: "";
        }
      });
    };
    onMounted(() => {
      getInfo();
    });
    return {
      formRef,
      formState,
      onSubmit,
      onFinishFailed,
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 8,
      },
    };
  },
});
</script>